import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

export const environment = {
  production: false,
  hideElttoUi: true,
  oidc: {
    apiPrefix: 'api/v1',
    issuer: 'https://auth.dev2.mountainseedapp.com/',
    allowedUrls: [
      'https://auth.dev2.mountainseedapp.com/',
      '/api/v1',
      'https://property-search.dev2.mountainseedapp.com',
      'https://workspace.dev2.mountainseedapp.com',
    ],
    app: 'mi',
  },
  ngxs: {
    plugins: [NgxsReduxDevtoolsPluginModule.forRoot()],
  },
  productUrls: {
    elttoUrl: 'https://eltto.dev2.mountainseedapp.com',
    miUrl: 'https://mi.dev2.mountainseedapp.com',
    authUrl: 'https://auth.dev2.mountainseedapp.com',
  },
  gaId: 'G-N8S2M90J40',
  googleMap: {
    mapId: '2e5981ea6d07830b',
    defaultDistance: 10, // 10 miles
    minDistance: 1,
    maxDistance: 200,
    maxMarkers: 1000,
    staticStreetViewApiKey: 'AIzaSyCjAOLPyHtVd_UToDvlHCCPgCepE0_2Eq0',
  },
  tableauService: {
    serverUrl: 'https://tableau-dev.mountainseedapp.com',
    timeoutRetriesAttempts: 1,
    resultsTimeoutDurationInSeconds: 5,
    mapTimeoutDurationInSeconds: 14,
    timeoutFiltersExpirationInMinutes: 60,
  },
  propertySearchChipBar: {
    chipRemovalReapplyFiltersDebounceTime: 1000,
  },
  serviceUrls: {
    propertySearch: 'https://property-search.dev2.mountainseedapp.com',
    workspace: 'https://workspace.dev2.mountainseedapp.com',
  },
  workspace: {
    savedSearchesTooltipLabel: `Saved searches allow you to save the criteria for searches you frequently run so that you don't have to re-enter the same criteria again later. A search may be saved from anywhere in the application where search criteria may be applied.</br>
    To run a saved search, select "Run Search" from the action menu.`,
    compsTooltipLabel: `Easily refer back to properties you frequently search and compare them all in one space. Once a property has been added to your workspace, you can view its details, export it into a PDF, or export data for multiple properties into a spreadsheet.`,
  },
  surveyMonkeyLink: 'https://www.surveymonkey.com/r/N5P6SFT',
};
